import React from 'react'

import Home from '../components/Home'
import { Layout, Navbar } from '../Layout'

const IndexPage = () => (
	<Layout
		title="Home"
		navbar={<Navbar position="absolute"/>}
		content={<Home/>}
		footer={null}
	/>
)

export default IndexPage
