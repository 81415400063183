import gql from 'graphql-tag'

export const LOG_GOOD_TWEET = gql`
  mutation logGoodTweet(
    $name: String, 
    $screen_name: String, 
    $tweet_id: String, 
    $full_text: String, 
    $date_tweeted: String
    ) {
      logGoodTweet(name: $name, screen_name: $screen_name, tweet_id: $tweet_id, full_text: $full_text, date_tweeted: $date_tweeted){
      id
    }
  }
`

export const LOG_EMAIL = gql`
  mutation logEmail($email: String) {
    logEmail(email: $email)
  }
`