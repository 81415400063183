import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import styled from 'styled-components'
import { Pane } from 'evergreen-ui'
import  Link from 'gatsby-link'
import { useStaticQuery, graphql } from 'gatsby' // to query for image data

import { Typography, Button, TextInput, Toast } from '../components/primitives'
import { LOG_EMAIL } from '../../utils/graphql/mutations'


const HomePageWrapper = styled(Pane)`
	display: flex;
	flex-direction: column;
	margin-top: -30px;

	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 1000px) { 
		flex-direction: row;
	}
`
const StageLeft = styled(Pane)`
	display: flex;
	flex: 1.2;
	background-image: 
	${({theme}) => theme.name === 'light' 
		? 'linear-gradient(to bottom, #FF416C, #FF4B2B)' 
		: 'linear-gradient(to bottom, #FF416C, #FF4B2B)'};
  background-size: cover;
	span {
		display: flex;
		flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
	}

	span .logo {
		animation-name: hand-wave;
		animation-duration: 0.75s;
		animation-iteration-count: infinite;
		animation-direction: alternate;
	}

	@keyframes hand-wave {
		from {
			transform: rotate(-10deg);
		}

		to {
			transform: rotate(0deg);
		}
	}
`
const StageRight = styled(Pane)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* padding: ${({theme}) => `${theme.layout.pagePadding}`}; */
	flex: 1;
`
const StartButton = styled(Button)`
	background-image: 
	${({theme}) => theme.name === 'light' 
		? 'linear-gradient(to bottom, #000, #000) !important' 
		: 'linear-gradient(to bottom, #000, #000) !important'};
	border-radius: 999px;
	padding: 16px 46px;
	margin: 36px 0;
	height: 54px;
`
const SubscribeButton = styled(Button)`
	background-image: 
	${({theme}) => theme.name === 'light' 
		? 'linear-gradient(to bottom, #000, #000) !important' 
		: 'linear-gradient(to bottom, #FFF, #FFF) !important'};
	padding: .75rem 1rem;
	height: 54px;
	margin: 0 1rem;
`

export const Home = () => {

	const [email, setEmail] = useState('')

	const [logEmail, { loading }] = useMutation(
		LOG_EMAIL, {
			onCompleted: () => {
				Toast.success({
					message: 'You\'re subscribed!'
				})
			},
			onError: (err) => {
				console.warn(err)
			}
		}
	)
	
	const { share } = useStaticQuery(graphql`
		query {
      share: file(name: {eq: "share"}) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
		}
	`)

	const doLogEmail = () => {
		if(email) {
			logEmail(
				{
					variables: {
						email
					}
				}
			)
		}
	}

	return (
		<HomePageWrapper>
			<StageLeft>
				<span>
					<div className="logo text-logo">💬</div>
					<Typography variant="h2" weight="black" color="#FFF" className="text-center">Utter.</Typography>
					<Typography variant="h5" weight="black" color="#FFF" className="text-center mt-2">Game night just got personal!</Typography>
					<StartButton>
						<Link to="/configuration">
							<Typography variant="h5" weight="black" color="#FFF" className="text-center" >Start game</Typography>
						</Link>
					</StartButton>
				</span>
			</StageLeft>
			<StageRight>
				<div className="flex flex-col my-20 w-full px-4 md:w-2/3">
					<Typography variant="h6" weight="bold" className="mb-2 self-start text-left">
							Subscribe
					</Typography>
					<Typography variant="body" weight="normal" className="mb-4 self-start text-left">
							Get the latest news about Utter Fun Games!
					</Typography>

					<div className="flex items-center">
						<TextInput 
							type="email" 
							name="email" 
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="flex-1"
							placeholder="Enter your email address" />
						<SubscribeButton
							onClick={() => doLogEmail()}
							loading={loading}
						>
							<Typography variant="body" weight="black" inverse={true} className="text-center m-0">Subscribe</Typography>
						</SubscribeButton>
					</div>
				</div>
				<div className="flex flex-col mb-20 w-full px-4 md:w-2/3">
					<div className="flex flex-col bg-white rounded-lg text-center justify-center items-center p-4">
						<div className="flex rounded bg-black h-12 w-12 justify-center items-center">
							<div className="logo text-3xl">💬</div>
						</div>

						<Typography variant="h6" weight="bold" color="#000" className="my-2">
							Utter
						</Typography>
						<Typography variant="small" weight="normal" color="rgba(110,110,110,1)" className="my-2">
							Add this app to your home screen for easy access and a better experience.
						</Typography>
						<Typography variant="small" weight="normal" color="#000" className="my-2">
							<div className="flex items-center">
								Tap <img src={share.childImageSharp.fluid.src} width="16" className="mx-2"/> then &apos;Add to Home Screen&apos;.
							</div>
						</Typography>
					</div>
				</div>
			</StageRight>
		</HomePageWrapper>
	)
}

export default Home
